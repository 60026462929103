//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import vueI18n from '@/vueI18n'
// import { i18n } from '@/vueI18n';
import moment from 'moment';

import { PlanModel } from '@/modules/plan/plan-model';
const { fields } = PlanModel;

export default {
  name: 'app-plan-list-table',

  mounted() {
    this.doFetchFirstPage()
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'entities.plan.fields.name',
          align: 'center',
        },
        {
          name: 'numberOfDays',
          field: 'numberOfDays',
          label: 'entities.plan.fields.numberOfDays',
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.plan.fields.dateOfCreation',
          align: 'center',
        },
        { 
          name: 'action', 
          field: 'action', 
          label: 'common.action', 
          align: 'center', 
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'plan/list/rows',   
      count: 'plan/list/count',
      loading: 'plan/list/loading',
      pagination: 'plan/list/pagination',
      destroyLoading: 'plan/destroy/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    language(){
      return vueI18n.locale
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.limit || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination.rowsPerPage);
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'plan/list/doFetch',
      doMountTable: 'plan/list/doMountTable',
      doToggleStatus: 'plan/form/doToggleStatus',
      doDestroy: 'plan/destroy/doDestroy',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      if (fieldName == 'name') {
        const valueEn = val ? val['en'] : null
        const valueAr = val ? val['ar'] : null
        const value = this.currentLanguageCode == 'ar'
          ? valueAr ? valueAr : valueEn
          : valueEn ? valueEn : valueAr
        return value ? value : 'ـــ'
      }
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      // return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
      }
      return  
    },

    async doFetchFirstPage() {
      return this.doFetch({
        filter: { type: 'default' },
        orderBy: 'createdAt',
        pagination: { sortBy: 'desc', limit: 10 }
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      return this.doFetch({
        filter: { type: 'default' },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: firstDocument,
          action: 'prev'
        }
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      return this.doFetch({
        filter: { type: 'default' },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: lastDocument,
          action: 'next'
        }
      });
    },
  },
};
